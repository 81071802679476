export const findUserByAccessToken = async (accessToken) => {
  const response = await fetch("https://www.googleapis.com/oauth2/v2/userinfo", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const result = await response.json();

  return result;
};

export const findOrCreateFile = async (folderId, fileName, accessToken) => {
  const query = encodeURIComponent(`name = '${fileName}' and '${folderId}' in parents and trashed = false`);
  const searchResponse = await fetch(`https://www.googleapis.com/drive/v3/files?q=${query}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const searchResult = await searchResponse.json();
  const file = searchResult.files?.find((file) => file.name === fileName);

  if (file) {
    return file.id;
  } else {
    const metadata = {
      name: fileName,
      parents: [folderId],
      mimeType: "application/json",
    };

    // Create a Blob for initial data
    const blob = new Blob([JSON.stringify({ decks: [], cards: [] })], { type: "application/json" });
    const formData = new FormData();
    formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json" }));
    formData.append("file", blob);

    // Send request to create the file with the Blob data
    const createResponse = await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        // "Content-Type": "multipart/related", // Not needed as FormData sets it automatically
      }),
      body: formData,
    });

    const createResult = await createResponse.json();

    return createResult.id; // Return the new file ID
  }
};

export const updateFileById = async (fileId, blobData, accessToken) => {
  const url = `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=media`;
  const response = await fetch(url, {
    method: "PATCH",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": blobData.type,
    }),
    body: blobData,
  });

  const result = await response.json();

  return result;
};

// export const findFileByIdAndUpdateDb = async (fileId, accessToken) => {
//   const url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
//   const response = await fetch(url, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   });

//   if (!response.ok) {
//     return;
//   }

//   try {
//     const blob = await response.blob();

//     // Import the Blob data into Dexie database
//     await importDB(blob);
//   } catch (error) {}
// };

export const findFolderById = async (folderId, accessToken) => {
  // Send request to Google Drive API to get the folder by its ID
  const searchResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${folderId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  // Check the response status
  if (searchResponse.status === 200) {
    // Parse the search response
    const folder = await searchResponse.json();

    return folder;
  } else {
    return null;
  }
};

export const findFolderByName = async (folderName, accessToken, parentFolderId = null) => {
  let query = `mimeType = 'application/vnd.google-apps.folder' and name = '${folderName.replace(/'/g, "\\'")}' and trashed = false`;
  if (parentFolderId) {
    query += ` and '${parentFolderId}' in parents`;
  }
  const encodedQuery = encodeURIComponent(query);

  try {
    const searchResponse = await fetch(`https://www.googleapis.com/drive/v3/files?q=${encodedQuery}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!searchResponse.ok) {
      throw new Error(`Failed to search for folder: ${searchResponse.statusText}`);
    }

    const searchResult = await searchResponse.json();
    // Assuming we want the first folder found
    return searchResult.files && searchResult.files.length > 0 ? searchResult.files[0] : null;
  } catch (error) {
    console.error("Error finding folder by name:", error);
    throw error;
  }
};

export const createFolder = async (folderName, accessToken, parentFolderId) => {
  const metadata = {
    name: folderName,
    mimeType: "application/vnd.google-apps.folder",
    parents: parentFolderId ? [parentFolderId] : [],
  };

  const createResponse = await fetch("https://www.googleapis.com/drive/v3/files", {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(metadata),
  });

  const createResult = await createResponse.json();
  return createResult;
};

export const findFileByName = async (fileName, accessToken, parentFolderId) => {
  let query = `name = '${fileName}' and trashed = false`;
  if (parentFolderId) {
    query += ` and '${parentFolderId}' in parents`;
  }
  const encodedQuery = encodeURIComponent(query);

  const searchResponse = await fetch(`https://www.googleapis.com/drive/v3/files?q=${encodedQuery}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const searchResult = await searchResponse.json();
  const file = searchResult.files?.find((file) => file.name === fileName);
  return file;
};

export const createRemoteDb = async (fileName, accessToken, parentFolderId, data) => {
  // Define metadata for a new file
  const metadata = {
    name: fileName,
    mimeType: "application/octet-stream", // MIME type for binary data
    parents: parentFolderId ? [parentFolderId] : [],
  };

  const formData = new FormData();
  formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json" }));
  formData.append("file", data); // Append the passed data blob

  const createResponse = await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart", {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      // Don't set Content-Type here, FormData will set it along with the boundary
    }),
    body: formData,
  });

  const createResult = await createResponse.json();
  return createResult;
};

export const findFileById = async (fileId, accessToken) => {
  if (!fileId) {
    throw new Error("File ID is required");
  }

  const searchResponse = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!searchResponse.ok) {
    throw new Error(`Error fetching file: ${searchResponse.statusText}`);
  }

  const file = await searchResponse.json();
  return file;
};
