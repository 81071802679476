import { useState, useEffect } from "react";
import { findAllCardsByDeckId } from "../services/data/cardDataService";
import { shuffleArray } from "../utilities/card";

export const useCardData = (deckId, open) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const loadedCards = await findAllCardsByDeckId(deckId);
        setCards(shuffleArray(loadedCards));
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };

    if (open) {
      fetchCards();
    }
  }, [open, deckId]);

  return cards;
};

export const useCardNavigation = (cardsLength) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showBack, setShowBack] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleFlip = () => setShowBack(!showBack);

  const handleNext = () => {
    if (currentIndex < cardsLength - 1) {
      setIsTransitioning(true);
      setShowBack(false);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setIsTransitioning(true);
      setShowBack(false);
      setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    if (isTransitioning) {
      setIsTransitioning(false);
    }
  }, [currentIndex, isTransitioning]);

  return { currentIndex, handleNext, handlePrev, handleFlip, showBack, isTransitioning, setCurrentIndex, setShowBack };
};
