import React from "react";
import { Divider, Link, Stack } from "@mui/material";

function PolicyLinks() {
  return (
    <Stack data-testid="policy-links" direction="row" spacing={1} alignItems="center" sx={{ fontSize: "12px", "& a:hover": { textDecoration: "underline" } }} marginBottom={2}>
      <Link href="https://www.basicflashcards.com/privacy" underline="none" target="_blank" rel="noopener noreferrer">
        Privacy policy
      </Link>
      <Divider orientation="vertical" flexItem />
      <Link href="https://www.basicflashcards.com/terms" underline="none" target="_blank" rel="noopener noreferrer">
        Terms of use
      </Link>
    </Stack>
  );
}

export default PolicyLinks;
