import React, { useState, useEffect } from "react";
import { Box, Dialog, IconButton, Paper, TextField, useMediaQuery, useTheme } from "@mui/material";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

const UpdateCard = ({ open, onClose, onSubmit, card }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLg = useMediaQuery(theme.breakpoints.up("md"));

  const [showBack, setShowBack] = useState(false);
  const [sideA, setSideA] = useState("");
  const [sideB, setSideB] = useState("");
  const [rows, setRows] = useState(6);

  useEffect(() => {
    if (isXs) {
      setRows(11);
    } else if (isMd) {
      setRows(6.25);
    } else if (isLg) {
      setRows(10);
    }
  }, [isXs, isMd, isLg]);

  useEffect(() => {
    if (card) {
      setSideA(card.sideA);
      setSideB(card.sideB);
    }
  }, [card]);

  const handleFlip = () => {
    setShowBack(!showBack);
  };

  const handleSave = () => {
    onSubmit(sideA, sideB);
    onClose();
    setShowBack(false);
  };

  const handleClose = () => {
    onClose();
    setShowBack(false);
  };

  return (
    <Dialog
      data-testid="update-card-dialog"
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          marginTop: { xs: -10, sm: 0 },
          display: "grid",
          gridTemplateAreas: {
            xs: `"content" "buttons"`,
            sm: `"buttons content"`,
          },
          gridTemplateColumns: { sm: "auto 1fr" },
          gridTemplateRows: { xs: "1fr auto" },
          borderRadius: 0,
          boxShadow: "none",
          backgroundColor: "transparent",
          height: { xs: "70%", sm: "90%", md: "50%" },
          width: { xs: "80%", sm: "90%", md: "60%" },
          perspective: "1000px",
        },
      }}>
      <Box
        sx={{
          gridArea: "buttons",
          display: "flex",
          flexDirection: { xs: "row", sm: "column" },
          justifyContent: "space-around",
          alignItems: "center",
          p: 1,
          height: "100%",
          width: "100%",
        }}>
        <IconButton
          data-testid="close-button"
          onClick={handleClose}
          sx={{
            border: "1px solid",
            borderColor: "text.light",
            backgroundColor: "button.easy",
            color: "text.light",
            "&:hover": {
              backgroundColor: "paper.dark",
            },
          }}>
          <CloseRoundedIcon fontSize="large" />
        </IconButton>
        <IconButton
          data-testid="flip-button"
          onClick={handleFlip}
          sx={{
            backgroundColor: "secondary.light",
            color: "text.dark",
            "&:hover": {
              backgroundColor: "secondary.main",
            },
          }}>
          <CachedRoundedIcon fontSize="large" />
        </IconButton>
        <IconButton
          data-testid="save-button"
          onClick={handleSave}
          sx={{
            border: "1px solid",
            borderColor: "text.light",
            backgroundColor: "button.easy",
            color: "text.light",
            "&:hover": {
              backgroundColor: "paper.dark",
            },
          }}>
          <SaveRoundedIcon fontSize="large" />
        </IconButton>
      </Box>

      <Box
        sx={{
          gridArea: "content",
          display: "flex",
          flexDirection: "column",
        }}>
        <Paper
          elevation={3}
          sx={{
            flexGrow: 1,
            borderRadius: 3,
            transition: "transform 0.4s",
            transformStyle: "preserve-3d",
            transform: showBack ? "rotateY(180deg)" : "rotateY(0deg)",
            position: "relative",
            height: "100%",
            m: 1,
          }}>
          {/* Front side of the card */}
          <Box
            sx={{
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}>
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  fontSize: "1.5rem",
                  lineHeight: "1.5",
                },
                p: 2,
              }}
              margin="dense"
              id="sideA"
              name="sideA"
              placeholder="Enter some juicy text..."
              multiline
              rows={rows}
              variant="outlined"
              fullWidth
              value={sideA}
              onChange={(event) => setSideA(event.target.value)}
            />
          </Box>
          {/* Back side of the card */}
          <Box
            sx={{
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "auto",
              transform: "rotateY(180deg)",
            }}>
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  fontSize: "1.5rem",
                  lineHeight: "1.5",
                },
                p: 2,
              }}
              margin="dense"
              id="sideB"
              name="sideB"
              placeholder="What do you want to recall..."
              multiline
              rows={rows}
              variant="outlined"
              fullWidth
              value={sideB}
              onChange={(event) => setSideB(event.target.value)}
            />
          </Box>
        </Paper>
      </Box>
    </Dialog>
  );
};

export default UpdateCard;
