import React, { useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, Link, TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import { findOrCreateDeck } from "../../services/data/deckDataService";
import { createCard } from "../../services/data/cardDataService";

const UploadDeck = ({ open, onClose, onCloseComplete }) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector((state) => state.auth.userId);

  const handleClose = () => {
    onClose();
    if (onCloseComplete) onCloseComplete();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if the file is a CSV
    if (file && file.type === "text/csv") {
      setFile(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        // Convert CSV text to table and log it
        const rows = text.split("\n").map((row) => row.split(","));
      };
      reader.readAsText(file);
    } else {
      // Handle the error case
      alert("Please upload a .csv file.");
      setFile(null);
    }
  };

  const handleUploadSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const rows = text.split("\n").map((row) => row.split(","));
      const uniqueDecks = {};

      for (let i = 1; i < rows.length; i++) {
        const [deckName, front, back] = rows[i];
        if (!deckName) continue; // Skip if deck name is empty

        if (!uniqueDecks[deckName]) {
          const deckId = await findOrCreateDeck(deckName.trim(), userId);
          uniqueDecks[deckName] = deckId;
        }

        // Create card for the deck
        const cardData = {
          sideA: front,
          sideB: back,
          createdBy: userId,
        };
        await createCard(cardData, uniqueDecks[deckName], userId);
      }
    };

    if (file) {
      reader.readAsText(file);
    }

    setTimeout(() => {
      setIsLoading(false);
      handleClose();
    }, 2000);
  };

  return (
    <Dialog
      data-testid="upload-deck-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          marginTop: { xs: -30, sm: 0 },
          display: "flex",
          flexDirection: "column",
          maxWidth: "none",
          width: { xs: "90%", sm: "70%", md: "40%" },
          borderRadius: 3,
        },
      }}>
      <DialogTitle>Upload Deck</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Please upload your decks using the structure outlined in our provided template to ensure smooth uploading of your data.
        </Typography>
        <Typography variant="body2">
          <Link href="/bfc_template.csv" download>
            Download the template file (bfc_template.csv)
          </Link>
        </Typography>
        <Box textAlign="center" my={2}>
          <Box my={4}>
            <CloudUploadIcon fontSize="large" color="primary" />
            <Typography variant="subtitle1" gutterBottom>
              Choose a .csv file to upload
            </Typography>
          </Box>

          <TextField
            data-testid="upload-file-input"
            type="file"
            onChange={handleFileChange}
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: ".csv",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button onClick={handleUploadSubmit} variant="contained" color="primary" fullWidth disabled={!file || isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDeck;
