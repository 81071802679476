import { Badge, Box, Typography } from "@mui/material";

const BrandTitle = () => {
  // return (
  //   <Box data-testid="brand-title" sx={{ mt: 4 }}>
  //     <Badge
  //       variant="string"
  //       color="secondary"
  //       badgeContent={<span>Basic</span>}
  //       anchorOrigin={{
  //         vertical: "top",
  //         horizontal: "left",
  //       }}
  //       sx={{
  //         ".MuiBadge-badge": {
  //           fontSize: "0.8em",
  //         },
  //       }}>
  //       <Typography
  //         variant="brand"
  //         component="h1"
  //         sx={{
  //           fontSize: "4rem",
  //         }}>
  //         FlashCards
  //       </Typography>
  //     </Badge>
  //   </Box>
  // );
};

export default BrandTitle;
