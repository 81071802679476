import React from "react";
import { ListItemIcon } from "@mui/material";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import { isTokenValid } from "../../../utilities/validateToken";

const SyncIcon = ({ isSyncing, tokenExpiration, iconStyle, rotate }) => {
  return (
    <ListItemIcon sx={{ ...iconStyle }}>
      {isSyncing ? (
        <SyncRoundedIcon sx={{ fontSize: 15, ...rotate }} />
      ) : isTokenValid(tokenExpiration) ? (
        <CloudDoneOutlinedIcon sx={{ fontSize: 15 }} />
      ) : (
        <CloudOffRoundedIcon sx={{ fontSize: 15 }} />
      )}
    </ListItemIcon>
  );
};

export default SyncIcon;
