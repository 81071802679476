import { createSlice } from "@reduxjs/toolkit";

//TODO: Limit the history

const initialState = {
  currentScreen: "MyDecks",
  history: [], // History stack for navigation
};

export const screenSlice = createSlice({
  name: "screen",
  initialState,
  reducers: {
    // Navigate to a new screen
    navigateTo: (state, action) => {
      const newScreen = action.payload;
      if (state.currentScreen !== newScreen) {
        state.history.push(state.currentScreen);
        state.currentScreen = newScreen;
      }
    },
    // Go back to the previous screen
    goBack: (state) => {
      if (state.history.length > 0) {
        state.currentScreen = state.history.pop();
      }
    },
    // Direct navigation to a specific screen (e.g., from breadcrumbs)
    directNavigate: (state, action) => {
      state.currentScreen = action.payload;
    },
    // Reset history on logout
    resetHistoryOnSignOut: (state) => {
      state.history = []; // Reset history
      state.currentScreen = "MyDecks";
    },
  },
});

// Action creators
export const { navigateTo, goBack, directNavigate, resetHistoryOnSignOut } = screenSlice.actions;

// Reducer
export default screenSlice.reducer;

// Selectors
export const selectCurrentScreen = (state) => state.screen.currentScreen;
export const selectNavigationHistory = (state) => state.screen.history;
