import React from "react";
import { Dialog, DialogContent, Stack } from "@mui/material";
import BrandTitle from "../common/BrandTitle";
import GoogleSignInButton from "../auth/GoogleSignInButton";
import PolicyLinks from "../common/PolicyLinks";

const Signin = ({ open, onClose }) => {
  return (
    <Dialog
      data-testid="signin-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          marginTop: { xs: -30, sm: 0 },
          display: "flex",
          flexDirection: "column",
          maxWidth: "none",
          width: { xs: "90%", sm: "70%", md: "40%" },
          height: { xs: "50%", sm: "90%", md: "50%" },
          borderRadius: 3,
        },
      }}>
      <DialogContent
        sx={{
          backgroundColor: "#fcdbb9",
        }}>
        <Stack spacing={6} alignItems="center" sx={{ mt: 5 }}>
          <BrandTitle />
          <GoogleSignInButton>Sign In with Google</GoogleSignInButton>
          <PolicyLinks />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Signin;
