import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Divider, Fade, IconButton, ListItemText, MenuItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import UploadIcon from "@mui/icons-material/Upload";
import db from "../../database/db";
import { createDeck, findAllDecksByUserId, findDeckById } from "../../services/data/deckDataService";
import CreateDeck from "../../components/dialogs/CreateDeck";
import UploadDeck from "../../components/dialogs/UploadDeck";
import BreadcrumbsNav from "../../components/common/BreadcrumbsNav";
import ListDropdownMenu from "../../components/menus/ListDropdownMenu";
import PaperList from "../../components/list/PaperList";
import PaperListPlaceholder from "../../components/list/PaperListPlaceholder";
import PaperListLoading from "../../components/list/PaperListLoading";
import { setCurrentDeckId, setCurrentDeckDetails } from "../../redux/features/deck/deckSlice";
import { navigateTo } from "../../redux/features/screen/screenSlice";

const MyDecksScreen = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [decks, setDecks] = useState([]);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [openCreateDeckDialog, setOpenCreateDeckDialog] = useState(false);
  const [openUploadDeckDialog, setOpenUploadDeckDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [deckCount, setDeckCount] = useState(null);
  const daysBeforeDeletion = useSelector((state) => state.syncing.daysBeforeDeletion);
  const lastSynced = useSelector((state) => state.syncing.lastSynced);
  const isSyncing = useSelector((state) => state.syncing.isSyncing);

  // Handle deck selection and navigate to that deck
  const handleDeckSelect = async (deckId) => {
    try {
      const deckDetails = await findDeckById(deckId);
      if (deckDetails) {
        dispatch(setCurrentDeckId(deckId));
        dispatch(setCurrentDeckDetails(deckDetails));
        dispatch(navigateTo("MyCards"));
      }
    } catch (error) {}
  };

  // Function to open the dialog
  const handleOpenCreateDeckDialog = () => {
    setOpenCreateDeckDialog(true);
  };

  // Function to close the dialog
  const handleCloseCreateDeckDialog = () => {
    setOpenCreateDeckDialog(false);
  };

  // Toggle function for the Edit/Done button
  const toggleEdit = () => {
    setIsEditClicked((prev) => !prev);
  };

  const handleOpenUploadDeckDialog = () => {
    setOpenUploadDeckDialog(true);
  };
  const handleCloseUploadDeckDialog = () => {
    setOpenUploadDeckDialog(false);
    refreshDecks();
  };

  const refreshDecks = async () => {
    try {
      const updatedDecks = await findAllDecksByUserId(userId);
      setDecks(updatedDecks);
      setDeckCount(updatedDecks.length);
    } catch (error) {
      // Handle error here if needed
    }
  };

  //TODO: Add condition to prevent jarring rerending when lastSynced is updated
  // GET Decks from IndexedDB
  useEffect(() => {
    const getDecks = async () => {
      if (!userId) {
        return;
      }

      let timeoutId = setTimeout(() => setIsLoading(true), 500);

      try {
        const loadedDecks = await findAllDecksByUserId(userId);
        clearTimeout(timeoutId);
        setDecks(loadedDecks);
        setDeckCount(loadedDecks.length);
      } catch (error) {
        // Handle error here if needed
      } finally {
        setIsLoading(false);
        setInitialLoad(false);
      }
    };

    getDecks();
  }, [userId, lastSynced]);

  // CREATE Decks
  const handleSubmitDeck = async (event) => {
    //TODO: Create Deck creates multiple decks if you double click the Dialog save button
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const newDeckName = formData.get("deckName");
    const newDeckDescription = formData.get("deckDescription");
    if (!userId) {
      return;
    }
    const newDeckData = {
      name: newDeckName,
      description: newDeckDescription,
      createdBy: userId,
    };

    try {
      createDeck(newDeckData, userId);
      const updatedDecks = await findAllDecksByUserId(userId);
      setDecks(updatedDecks);
      setDeckCount(updatedDecks.length);
    } catch (error) {
    } finally {
      handleCloseCreateDeckDialog();
    }
  };

  // SOFT DELETE Decks
  const handleSoftDelete = async (event, deckId) => {
    event.stopPropagation();
    try {
      const deck = await db.decks.get(deckId);

      if (!deck) {
        return;
      }

      // Create a new Date object for the current time
      const now = new Date();
      const deletionDate = new Date(now);
      deletionDate.setDate(deletionDate.getDate() + daysBeforeDeletion);
      deck.deletedOn = deletionDate.toISOString();
      deck.modifiedOn = now.toISOString();

      await db.decks.put(deck);

      const updatedDecks = await findAllDecksByUserId(userId);
      setDecks(updatedDecks);
      setDeckCount(updatedDecks.length); // Set the total number of decks
    } catch (error) {}
  };

  const getCharLimit = () => (isSmallScreen ? 25 : 55);

  const deckItemText = (deck) => {
    const charLimit = getCharLimit();
    return (
      <Typography variant="body1" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }}>
        {deck.name.length > charLimit ? deck.name.substring(0, charLimit) + "..." : deck.name}
      </Typography>
    );
  };

  //TODO: Add get cardCount and add at end of record
  const deckSecondaryText = (deck) => {
    // const cardCountStr = deck.cardCount !== undefined ? deck.cardCount.toString() : "0";

    return (
      <Typography variant="body2" color="text.secondary" sx={{ marginRight: 1, fontSize: { xs: ".8rem", sm: ".9rem" } }}>
        {/* {cardCountStr} */}
      </Typography>
    );
  };

  // Secondary action for PaperList
  const deckSecondaryAction = (deck) => (
    <Fade in={isEditClicked} timeout={300} unmountOnExit>
      <Stack direction="row" spacing={0.25}>
        <IconButton edge="end" aria-label="more" onClick={(e) => handleSoftDelete(e, deck.id)}>
          <DeleteOutlineRoundedIcon color="primary" />
        </IconButton>
      </Stack>
    </Fade>
  );

  return (
    <Stack data-testid="mydecks-screen" spacing={1.5}>
      <Box>
        <BreadcrumbsNav />
      </Box>

      <ListDropdownMenu title="My Decks">
        <MenuItem onClick={() => dispatch(navigateTo("MyDecks"))}>
          <ListItemText primary="My Decks" />
        </MenuItem>
        <MenuItem onClick={() => dispatch(navigateTo("DecksBin"))}>
          <ListItemText primary="Recently Deleted" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleOpenUploadDeckDialog}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <ListItemText primary="Upload" />
            <UploadIcon />
          </Box>
        </MenuItem>
        {/* <MenuItem disabled onClick={() => dispatch(navigateTo("MyDecks"))}>
          <ListItemText disabled primary="Public Decks" />
        </MenuItem>
        <MenuItem disabled onClick={() => dispatch(navigateTo("MyDecks"))}>
          <ListItemText primary="Shared Decks" />
        </MenuItem> */}
      </ListDropdownMenu>

      <Stack>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" alignItems="center">
            <Button
              startIcon={<AddRoundedIcon sx={{ color: "text.primary" }} />}
              variant="text"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.1rem",
                },
                color: "text.primary",
                borderRadius: 1.5,
              }}
              onClick={handleOpenCreateDeckDialog}>
              Deck
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="text"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.1rem",
                },
                color: "text.primary",
                borderRadius: 1.5,
              }}
              onClick={toggleEdit}>
              {isEditClicked ? "Done" : "Edit"}
            </Button>
          </Box>
        </Box>
      </Stack>

      {isLoading || (isSyncing && deckCount === 0) ? (
        <PaperListLoading count={5} itemHeight="3rem" dividerInsetMargin="3rem" showPrimaryAction={true} showSecondaryText={false} />
      ) : !initialLoad && deckCount === 0 ? (
        <PaperListPlaceholder icon={LayersRoundedIcon} primaryText="No decks found" secondaryText="Create a new deck to get started." />
      ) : (
        <PaperList
          items={decks}
          onItemSelect={handleDeckSelect}
          isEditClicked={isEditClicked}
          ItemIcon={LayersRoundedIcon}
          secondaryAction={deckSecondaryAction}
          itemText={deckItemText}
          itemSecondaryText={deckSecondaryText}
          showForwardIcon={true}
          itemHeight="3rem"
          dividerInsetMargin="3rem"
        />
      )}

      <CreateDeck open={openCreateDeckDialog} onClose={handleCloseCreateDeckDialog} onSubmit={handleSubmitDeck} />
      <UploadDeck open={openUploadDeckDialog} onClose={handleCloseUploadDeckDialog} onCloseComplete={refreshDecks} />
    </Stack>
  );
};

export default MyDecksScreen;
