import { findFolderByName, createFolder, findFileByName, findFileById, createRemoteDb, updateFileById } from "../drive/googleDriveService";
import db from "../../database/db";
import v1_template from "../../database/v1_template.json";
import { updateSyncing, updateLastSynced } from "../../redux/features/syncing/syncingSlice";
import { isTokenValid } from "../../utilities/validateToken";

let isSettingUpFolders = false;

export const syncData = async (accessToken, tokenExpiration, dispatch, updateGoogleParentId, updateGoogleDataId, updateGoogleDataFileId, lastSyncedTime, daysBeforeDeletion, userId) => {
  // Check if the token is valid before starting sync
  if (!isTokenValid(tokenExpiration)) {
    return;
  }

  // Avoid concurrent execution of setupFolders
  if (isSettingUpFolders) {
    return;
  }

  isSettingUpFolders = true;

  const newSyncedTime = new Date().toISOString();

  const findOrCreateFolder = async (folderName, parentFolderId = null) => {
    let folder = await findFolderByName(folderName, accessToken, parentFolderId);
    if (!folder) {
      folder = await createFolder(folderName, accessToken, parentFolderId);
    }

    return folder.id;
  };

  const setupFolders = async () => {
    // Create or find the "Mnemonic" folder at the root level
    const basicAppsFolderId = await findOrCreateFolder("Mnemonic");

    // Create or find the "FlashCards" folder within the "Basic Apps" folder
    const appFolderId = await findOrCreateFolder("FlashCards", basicAppsFolderId);

    dispatch(updateGoogleParentId(appFolderId));

    // Create or find the "data" folder within the "FlashCards" folder
    const dataFolderId = await findOrCreateFolder("Data", appFolderId);

    dispatch(updateGoogleDataId(dataFolderId));

    let dataFileId = null;
    const dataFile = await findFileByName("data", accessToken, dataFolderId);
    if (!dataFile) {
      // Stringify the JSON data before creating the blob
      const placeholderData = JSON.stringify(v1_template);
      const newDataFile = await createRemoteDb("data", accessToken, dataFolderId, new Blob([placeholderData], { type: "text/json" }));
      dataFileId = newDataFile.id;
    } else {
      dataFileId = dataFile.id;
    }

    dispatch(updateGoogleDataFileId(dataFileId));
    return dataFileId;
  };

  const pullLocalDb = async () => {
    try {
      const tables = db.tables.map((table) => table.name);

      // Calculate the difference in milliseconds between the current date and the lastSyncedTime
      const currentDate = new Date();
      const lastSyncDate = new Date(lastSyncedTime);
      const diffTime = Math.abs(currentDate - lastSyncDate);

      // Number of milliseconds to check for data expiry
      const expiryDays = daysBeforeDeletion;
      const expiryDurationMs = expiryDays * 24 * 60 * 60 * 1000;

      if (diffTime > expiryDurationMs) {
        for (const tableName of tables) {
          await db.table(tableName).clear();
        }

        return null;
      } else {
        const data = await Promise.all(
          tables.map(async (tableName) => {
            const rows = await db.table(tableName).where({ createdBy: userId }).toArray();
            const schema =
              db.table(tableName).schema.primKey.src +
              "," +
              db
                .table(tableName)
                .schema.indexes.map((idx) => idx.src)
                .join(",");
            return {
              tableName,
              inbound: true,
              rows,
              schema,
            };
          })
        );

        const exportData = {
          formatName: "dexie",
          formatVersion: 1,
          data: {
            databaseName: db.name,
            databaseVersion: db.verno,
            tables: data.map(({ tableName, schema, rows }) => ({
              name: tableName,
              schema,
              rowCount: rows.length,
            })),
            data: data.map(({ tableName, inbound, rows }) => ({
              tableName,
              inbound,
              rows,
            })),
          },
        };

        return exportData;
      }
    } catch (error) {
      return null;
    }
  };

  const pullRemoteDb = async (dataFileId) => {
    try {
      const remoteFile = await findFileById(dataFileId, accessToken);
      const fileContentUrl = `https://www.googleapis.com/drive/v3/files/${remoteFile.id}?alt=media`;
      const blobResponse = await fetch(fileContentUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (!blobResponse.ok) {
        throw new Error(`Error fetching blob: ${blobResponse.statusText}`);
      }

      const remoteBlob = await blobResponse.blob();
      const blobText = await remoteBlob.text();
      const blobJson = JSON.parse(blobText);
      return blobJson;
    } catch (error) {
      return null;
    }
  };

  const mergeLocalAndRemoteDB = async (localBlob, remoteBlob) => {
    if (!remoteBlob) {
      // If remote data is not available, no merging can be done
      return null;
    }

    let mergedData;

    const mergeRows = (localRows, remoteRows) => {
      const merged = {};

      localRows.forEach((row) => {
        merged[row.id] = { ...row };
      });

      remoteRows.forEach((row) => {
        if (!merged[row.id] || new Date(row.modifiedOn) > new Date(merged[row.id].modifiedOn)) {
          merged[row.id] = { ...row };
        }
      });

      return Object.values(merged);
    };

    if (!localBlob || !localBlob.data) {
      // If local data is empty, use remote data as the base for merged data
      mergedData = remoteBlob;
    } else {
      // Merge rows when both local and remote data are present
      mergedData = {
        formatName: localBlob.formatName,
        formatVersion: localBlob.formatVersion,
        data: {
          databaseName: localBlob.data.databaseName,
          databaseVersion: localBlob.data.databaseVersion,
          tables: localBlob.data.tables,
          data: localBlob.data.data.map((localTable) => {
            const remoteTableData = remoteBlob.data.data.find((t) => t.tableName === localTable.tableName);
            if (!remoteTableData) {
              return localTable;
            }
            return {
              ...localTable,
              rows: mergeRows(localTable.rows, remoteTableData.rows),
            };
          }),
        },
      };
    }

    return mergedData;
  };

  const pushLocalDb = async (mergedData) => {
    try {
      for (const mergedTable of mergedData.data.data) {
        if (mergedTable.rows.length === 0) {
          continue;
        }

        const rowsToUpdate = [];
        const idsToDelete = [];

        for (const row of mergedTable.rows) {
          if (row.deletedOn && new Date(row.deletedOn) < new Date()) {
            idsToDelete.push(row.id);
          } else {
            const cleanedRow = { ...row };
            delete cleanedRow.$; // Remove the '$' property
            delete cleanedRow.$types; // Remove the '$types' property
            rowsToUpdate.push(cleanedRow);
          }
        }

        // Update rows
        if (rowsToUpdate.length > 0) {
          await db.table(mergedTable.tableName).bulkPut(rowsToUpdate);
        }

        // Delete rows
        if (idsToDelete.length > 0) {
          await db.table(mergedTable.tableName).bulkDelete(idsToDelete);
        }
      }
    } catch (error) {}
  };

  const pushRemoteDb = async (mergedData, dataFileId) => {
    try {
      // Prepare data for remote upload, excluding rows with deletedOn date in the past
      const dataForRemote = {
        ...mergedData,
        data: {
          ...mergedData.data,
          data: mergedData.data.data.map((table) => ({
            ...table,
            rows: table.rows.filter((row) => !(row.deletedOn && new Date(row.deletedOn) < new Date())),
          })),
        },
      };

      // Convert the prepared data into a Blob
      const mergedDataBlob = new Blob([JSON.stringify(dataForRemote)], { type: "application/json" });

      // Upload the Blob to the remote database
      await updateFileById(dataFileId, mergedDataBlob, accessToken);
    } catch (error) {}
  };

  const initialize = async () => {
    if (!accessToken) return;

    dispatch(updateSyncing(true));

    try {
      const dataFileId = await setupFolders();
      const localBlob = await pullLocalDb();
      const remoteBlob = await pullRemoteDb(dataFileId);

      const mergedData = await mergeLocalAndRemoteDB(localBlob, remoteBlob);
      if (mergedData) {
        await pushLocalDb(mergedData);
        await pushRemoteDb(mergedData, dataFileId);
      }
    } catch (error) {
    } finally {
      isSettingUpFolders = false;
      dispatch(updateSyncing(false));
      dispatch(updateLastSynced(newSyncedTime));
    }
  };

  initialize();
};
