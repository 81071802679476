import Dexie from "dexie";

const db = new Dexie("BasicFlashCardsDB");

db.version(1).stores({
  decks:
    "id, name, description, createdOn, modifiedOn, deletedOn, syncedOn, *cards, createdBy, *owners, *viewers, *editors, isDeleted, averageEaseFactor, reviewAt, masteryLevel, progress, lastActive, reviewSettings",
  cards:
    "id, deckId, sideA, sideB, createdOn, modifiedOn, deletedOn, syncedOn, reviewedOn, reviewAt, reviewFrequency, difficultyRating, notes, easeFactor, interval, repetitions, lapses, lastGrade, flag, createdBy, isDeleted, isOverdue, startingEaseFactor, learningStep, overdueFactor, reviewHistory, additionalStats",
});

export default db;

// easeFactor: Adjusts based on user's rating of card difficulty.
// interval: Time until the card should be reviewed again.
// repetitions: Increments with each review.
// lastGrade: User's rating of card difficulty in the last review.
