import React, { useState } from "react";
import { ListItemIcon, ListItemText, Menu, Stack, Typography } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const ListDropdownMenu = ({ title, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack data-testid="list-dropdown-menu" direction="row">
        <Stack alignItems="center" direction="row" onClick={handleClick}>
          <ExpandMoreRoundedIcon
            sx={{
              cursor: "pointer",
              fontSize: {
                xs: "1.7rem",
                lg: "2rem",
              },
              color: "text.primary",
            }}
          />
          <Typography
            variant="h6"
            component="h1"
            sx={{
              cursor: "pointer",
              fontSize: {
                xs: "1.7rem",
                lg: "2rem",
              },
              fontWeight: "600",
              color: "text.primary",
              maxWidth: {
                xs: "300px",
                sm: "500px",
              },
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}>
            {title}
          </Typography>
        </Stack>
      </Stack>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onClick: () => {
              handleClose();
              child.props.onClick?.();
            },
            ...(child.type === ListItemIcon && { sx: { fontSize: { xs: "1.6rem", lg: "2rem" } } }),
            ...(child.type === ListItemText && { primaryTypographyProps: { variant: "body2", sx: { fontSize: { xs: "1.6rem", lg: "2rem" } } } }),
          })
        )}
      </Menu>
    </div>
  );
};

export default ListDropdownMenu;
