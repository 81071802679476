import React from "react";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Skeleton } from "@mui/material";
//TODO: Write test

const PaperListLoading = ({ count, itemHeight, dividerInsetMargin, showPrimaryAction, showSecondaryText }) => {
  return (
    <Paper data-testid="paper-list-loading" elevation={0} sx={{ borderRadius: 3, overflow: "hidden" }}>
      <List disablePadding>
        {Array.from({ length: count }, (_, index) => (
          <React.Fragment key={index}>
            <ListItem
              disablePadding
              sx={{
                "&:first-of-type .MuiListItemButton-root": { borderTopLeftRadius: 3, borderTopRightRadius: 3 },
                "&:last-of-type .MuiListItemButton-root": { borderBottomLeftRadius: 3, borderBottomRightRadius: 3 },
                height: itemHeight,
              }}>
              <ListItemButton sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {showPrimaryAction && (
                  <ListItemIcon sx={{ minWidth: "auto", marginRight: "0.5rem" }}>
                    <Skeleton variant="rounded" width={24} height={24} />
                  </ListItemIcon>
                )}
                <ListItemText primary={<Skeleton variant="text" width="80%" />} secondary={showSecondaryText ? <Skeleton variant="text" width="60%" /> : null} />
              </ListItemButton>
            </ListItem>
            {index !== count - 1 && <Divider variant="inset" sx={{ marginLeft: dividerInsetMargin }} />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default PaperListLoading;
