import { useState, useEffect } from "react";
import { findDeckById, updateDeck } from "../services/data/deckDataService";

export const useDeckDetails = (deckId, open, onClose) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const getDeck = async () => {
      if (deckId) {
        try {
          const deck = await findDeckById(deckId);
          if (deck) {
            setName(deck.name);
            setDescription(deck.description);
          }
        } catch (error) {
          console.error("Error fetching deck details:", error);
        }
      }
    };

    if (open) {
      getDeck();
    }
  }, [deckId, open]);

  const saveDeck = async () => {
    try {
      await updateDeck(deckId, { name, description });
      const updatedDeckDetails = await findDeckById(deckId);
      return updatedDeckDetails;
    } catch (error) {
      console.error("Error updating deck:", error);
      return null;
    } finally {
      onClose();
    }
  };

  return { name, description, setName, setDescription, saveDeck };
};
