import db from "../../database/db";
import { v4 as uuidv4 } from "uuid";

export const createCard = async (card, deckId, userId) => {
  const now = new Date().toISOString();
  card.id = uuidv4();
  card.deckId = deckId;
  card.sideA = card.sideA || "";
  card.sideB = card.sideB || "";
  card.createdOn = now;
  card.createdBy = userId;
  card.modifiedOn = card.modifiedOn || now;
  card.reviewedOn = card.reviewedOn || now;
  card.reviewAt = card.reviewAt || now;
  card.reviewFrequency = card.reviewFrequency || null;
  card.difficultyRating = card.difficultyRating || 3;
  card.notes = card.notes || "";
  card.easeFactor = card.easeFactor || 2.5;
  card.interval = card.interval || 1;
  card.repetitions = card.repetitions || 0;
  card.lapses = card.lapses || 0;
  card.lastGrade = card.lastGrade || null;
  card.flag = card.flag || false;
  card.isDeleted = card.isDeleted || false;
  card.deletedOn = card.deletedOn || null;
  card.syncedOn = card.syncedOn || null;
  return await db.cards.add(card);
};

export const findAllCardsByDeckId = async (deckId, includeDeleted = false) => {
  if (!deckId) return [];

  try {
    const filterCondition = includeDeleted
      ? (card) => card.deckId === deckId && !card.isDeleted && card.deletedOn !== null
      : (card) => card.deckId === deckId && !card.isDeleted && (card.deletedOn === undefined || card.deletedOn === null);

    const cards = await db.cards.filter(filterCondition).toArray();

    // Sort cards by modifiedOn date in descending order
    cards.sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn));

    return cards;
  } catch (error) {
    console.error("Error loading cards from IndexedDB:", error);
    throw error;
  }
};

export const updateCard = async (cardId, updates) => {
  if (!cardId || !updates) return null;

  try {
    const card = await db.cards.get({ id: cardId });
    if (!card) {
      console.error("Card not found:", cardId);
      return null;
    }

    const updatedFields = {
      ...updates,
      modifiedOn: new Date(),
    };

    await db.cards.update(cardId, updatedFields);
    return await db.cards.get({ id: cardId });
  } catch (error) {
    console.error("Error updating card in IndexedDB:", error);
    throw error;
  }
};
