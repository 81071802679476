import React from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useDeckDetails } from "../../hooks/deck";
import { setCurrentDeckDetails } from "../../redux/features/deck/deckSlice";

const UpdateDeck = ({ open, onClose, deckId }) => {
  const { name, description, setName, setDescription, saveDeck } = useDeckDetails(deckId, open, onClose);

  const dispatch = useDispatch();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const updatedDeckDetails = await saveDeck();
    if (updatedDeckDetails) {
      dispatch(setCurrentDeckDetails(updatedDeckDetails));
    }
  };

  return (
    <Dialog
      data-testid="update-deck-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          marginTop: { xs: -30, sm: 0 },
          display: "flex",
          flexDirection: "column",
          maxWidth: "none",
          width: { xs: "90%", sm: "70%", md: "40%" },

          borderRadius: 3,
        },
      }}>
      <form onSubmit={handleFormSubmit} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <DialogContent
          sx={{
            padding: "10px 24px 10px 24px",
            overflowY: "auto",
            flexGrow: 1,
          }}>
          <TextField autoFocus margin="dense" id="deckName" name="deckName" label="Deck Name" type="text" fullWidth variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
          <TextField
            margin="dense"
            id="deckDescription"
            name="deckDescription"
            label="Description"
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: "0px 24px 10px 24px",
            flexShrink: 0,
          }}>
          <Button variant="outlined" onClick={onClose} fullWidth>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateDeck;
