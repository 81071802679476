import React from "react";
import { Tooltip, Box, Dialog, IconButton, Paper, Typography } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useCardData, useCardNavigation } from "../../hooks/card";

const StudyCards = ({ open, onClose, deckId }) => {
  const cards = useCardData(deckId, open);
  const { currentIndex, handleNext, handlePrev, handleFlip, showBack, isTransitioning, setCurrentIndex, setShowBack } = useCardNavigation(cards.length);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setShowBack(false);
      setCurrentIndex(0);
    }, 150);
  };

  const currentCard = cards[currentIndex] || { sideA: "", sideB: "" };

  return (
    <Dialog
      data-testid="review-card-dialog"
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          display: "grid",
          gridTemplateAreas: {
            xs: `"content" "buttons"`,
            sm: `"buttons content"`,
          },
          gridTemplateColumns: { sm: "auto 1fr" },
          gridTemplateRows: { xs: "1fr auto" },
          borderRadius: 0,
          boxShadow: "none",
          backgroundColor: "transparent",
          height: { xs: "70%", sm: "90%", md: "50%" },
          width: { xs: "80%", sm: "90%", md: "60%" },
          perspective: "1000px",
        },
      }}>
      <Box
        sx={{
          gridArea: "buttons",
          display: "flex",
          flexDirection: { xs: "row", sm: "column" },
          justifyContent: "space-around",
          alignItems: "center",
          p: 1,
          height: "100%",
          width: "100%",
        }}>
        <IconButton
          data-testid="prev-button"
          onClick={handlePrev}
          disabled={currentIndex === 0}
          sx={{
            backgroundColor: "button.easy",
            color: "text.dark",
            "&:hover": {
              backgroundColor: "paper.dark",
            },
          }}>
          <ArrowBackIosNewRoundedIcon fontSize="large" />
        </IconButton>
        <Tooltip
          title={
            <>
              <Typography variant="h6" color="inherit" style={{ fontSize: 14 }}>
                Easy
              </Typography>
              <Typography variant="body1" color="inherit" style={{ fontSize: 12 }}>
                You recalled the answer quickly and easily.
              </Typography>
              <Typography variant="body1" color="inherit" style={{ fontSize: 12 }}>
                See this card again after a long while.
              </Typography>
            </>
          }
          placement="left"
          arrow>
          <IconButton
            data-testid="easy-button"
            onClick={handleNext}
            disabled={currentIndex === cards.length - 1}
            sx={{
              border: "1px solid",
              borderColor: "text.light",
              backgroundColor: "button.easy",
              color: "text.light",
              "&:hover": {
                backgroundColor: "paper.dark",
              },
            }}>
            <div style={{ width: 36, height: 36 }}></div>
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            <>
              <Typography variant="h6" color="inherit" style={{ fontSize: 14 }}>
                Moderate
              </Typography>
              <Typography variant="body1" color="inherit" style={{ fontSize: 12 }}>
                You recalled the answer with some hesitation.
              </Typography>
              <Typography variant="body1" color="inherit" style={{ fontSize: 12 }}>
                See this card again after a moderate interval.
              </Typography>
            </>
          }
          placement="left"
          arrow>
          <IconButton
            data-testid="moderate-button"
            onClick={handleNext}
            disabled={currentIndex === cards.length - 1}
            sx={{
              border: "1px solid",
              borderColor: "text.dark",
              backgroundColor: "button.moderate",
              "&:hover": {
                backgroundColor: "secondary.dark",
                color: "secondary.dark",
              },
            }}>
            <div style={{ width: 36, height: 36 }}></div>
          </IconButton>
        </Tooltip>

        <Tooltip
          title={
            <>
              <Typography variant="h6" color="inherit" style={{ fontSize: 14 }}>
                Hard
              </Typography>
              <Typography variant="body1" color="inherit" style={{ fontSize: 12 }}>
                You struggled but eventually recalled the answer.
              </Typography>
              <Typography variant="body1" color="inherit" style={{ fontSize: 12 }}>
                See this card again soon.
              </Typography>
            </>
          }
          placement="left"
          arrow>
          <IconButton
            data-testid="hard-button"
            onClick={handleNext}
            disabled={currentIndex === cards.length - 1}
            sx={{
              backgroundColor: "button.hard",
              color: "primary.text",
              "&:hover": {
                backgroundColor: "secondary.dark",
              },
            }}>
            <div style={{ width: 36, height: 36 }}></div>
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            <>
              <Typography variant="h6" color="inherit" style={{ fontSize: 14 }}>
                Very Hard
              </Typography>
              <Typography variant="body1" color="inherit" style={{ fontSize: 12 }}>
                You couldn't recall the answer or it took a lot of effort.
              </Typography>
              <Typography variant="body1" color="inherit" style={{ fontSize: 12 }}>
                See this card in a few minutes.
              </Typography>
            </>
          }
          placement="left"
          arrow>
          <IconButton
            data-testid="veryHard-button"
            onClick={handleNext}
            disabled={currentIndex === cards.length - 1}
            sx={{
              backgroundColor: "button.veryHard",
              color: "text.light",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}>
            <div style={{ width: 36, height: 36 }}></div>
          </IconButton>
        </Tooltip>
      </Box>

      <Box
        onClick={handleFlip}
        sx={{
          gridArea: "content",
          display: "flex",
          flexDirection: "column",
          // border: "2px solid purple",
        }}>
        <Paper
          elevation={3}
          sx={{
            flexGrow: 1,
            borderRadius: 3,
            transition: isTransitioning ? "none" : "transform 0.4s",
            transformStyle: "preserve-3d",
            transform: showBack ? "rotateY(180deg)" : "rotateY(0deg)",
            position: "relative",
            height: "100%",
            m: 1,
          }}>
          {/* Front side of the card */}
          <Box
            sx={{
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}>
            <div style={{ padding: "10px" }}>
              <Typography variant="h5" align="left" color="text.primary" sx={{ m: 4 }}>
                {currentCard.sideA}
              </Typography>
            </div>
          </Box>
          {/* Back side of the card */}
          <Box
            sx={{
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "auto",
              transform: "rotateY(180deg)",
            }}>
            <div style={{ padding: "10px" }}>
              <Typography variant="h5" align="left" color="text.primary" sx={{ m: 4 }}>
                {currentCard.sideB}
              </Typography>
            </div>
          </Box>
        </Paper>
      </Box>
    </Dialog>
  );
};

export default StudyCards;
