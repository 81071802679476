// src/utils/authUtils.js

/**
 * Checks if the provided token expiration time is still valid.
 * @param {number} tokenExpiration - The expiration time of the token in seconds since the epoch.
 * @returns {boolean} - True if the current time is before the token expiration time.
 */
export const isTokenValid = (tokenExpiration) => {
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime < tokenExpiration;
};
