import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: null,
    userId: null,
    googleAccessToken: null,
    tokenExpiration: null,
  },
  reducers: {
    signIn: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.userId = action.payload.userId;
    },
    signOut: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.userId = null;
      state.googleAccessToken = null;
      state.tokenExpiration = null;
    },
    updateAccessToken: (state, action) => {
      state.googleAccessToken = action.payload.token;
      const currentTime = Math.floor(Date.now() / 1000);
      state.tokenExpiration = currentTime + action.payload.expiresIn;
      // state.tokenExpiration = currentTime;
    },
  },
});

export const { signIn, signOut, updateAccessToken } = authSlice.actions;
export default authSlice.reducer;
