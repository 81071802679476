import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "./auth";
import { isTokenValid } from "../utilities/validateToken";
import { syncData } from "../services/data/syncServices";
import { updateGoogleParentId, updateGoogleDataId, updateGoogleDataFileId } from "../redux/features/drive/cloudStorageSlice";

const useSyncStatus = () => {
  const { isAuthenticated } = useAuth();
  const tokenExpiration = useSelector((state) => state.auth.tokenExpiration);
  const isSyncing = useSelector((state) => state.syncing.isSyncing);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);

  useEffect(() => {
    if (isTokenValid(tokenExpiration)) {
      setOpenSignInDialog(false);
    }
  }, [tokenExpiration]);

  return { isAuthenticated, tokenExpiration, isSyncing, openSignInDialog, setOpenSignInDialog };
};

const useSync = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, tokenExpiration, setOpenSignInDialog } = useSyncStatus();
  const userId = useSelector((state) => state.auth.userId);
  const accessToken = useSelector((state) => state.auth.googleAccessToken);
  const lastSyncedTime = useSelector((state) => state.syncing.lastSynced);
  const daysBeforeDeletion = useSelector((state) => state.syncing.daysBeforeDeletion);

  const sync = useCallback(async () => {
    if (isAuthenticated && accessToken && isTokenValid(tokenExpiration)) {
      await syncData(accessToken, tokenExpiration, dispatch, updateGoogleParentId, updateGoogleDataId, updateGoogleDataFileId, lastSyncedTime, daysBeforeDeletion, userId);
    } else {
      setOpenSignInDialog(true);
    }
  }, [isAuthenticated, accessToken, tokenExpiration, dispatch, lastSyncedTime, daysBeforeDeletion, userId, setOpenSignInDialog]);

  return sync;
};

export { useSyncStatus, useSync };
