import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Fade, IconButton, ListItemText, MenuItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import RecyclingRoundedIcon from "@mui/icons-material/RecyclingRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import db from "../../database/db";
import { findAllCardsByDeckId } from "../../services/data/cardDataService";
import BreadcrumbsNav from "../../components/common/BreadcrumbsNav";
import ListDropdownMenu from "../../components/menus/ListDropdownMenu";
import { navigateTo } from "../../redux/features/screen/screenSlice";
import PaperList from "../../components/list/PaperList";
import PaperListPlaceholder from "../../components/list/PaperListPlaceholder";
import DeleteCard from "../../components/dialogs/DeleteCard";

const CardsBinScreen = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const deckId = useSelector((state) => state.deck.currentDeckId);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [cards, setCards] = useState([]);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const lastSynced = useSelector((state) => state.syncing.lastSynced);
  const daysBeforeDeletion = useSelector((state) => state.syncing.daysBeforeDeletion);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);

  // Toggle function for the Edit/Done button
  const toggleEdit = () => {
    setIsEditClicked((prev) => !prev);
  };

  const openDeleteDialog = (cardId) => {
    setCardToDelete(cardId);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (cardToDelete) {
      await handleHardDelete(cardToDelete);
    }
    setDeleteDialogOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // GET Cards from IndexedDB
  useEffect(() => {
    const getCards = async () => {
      if (!deckId) {
        setCards([]);
        return;
      }

      try {
        const loadedCards = await findAllCardsByDeckId(deckId, true);
        setCards(loadedCards);
      } catch (error) {}
    };
    getCards();
  }, [deckId, lastSynced]);

  const handleRestore = async (cardId) => {
    try {
      const card = await db.cards.get(cardId);

      if (!card) {
        return;
      }

      card.deletedOn = null;
      card.modifiedOn = new Date().toISOString();

      await db.cards.put(card);

      const updatedCards = await findAllCardsByDeckId(deckId, true);
      setCards(updatedCards);
    } catch (error) {
      <Typography variant="body1">Umm...couldn't load cards 😶</Typography>;
    }
  };

  const handleHardDelete = async (cardId) => {
    try {
      const card = await db.cards.get(cardId);
      if (!card) {
        return;
      }

      const now = new Date();
      const deletionDate = new Date(now);
      deletionDate.setDate(deletionDate.getDate() + daysBeforeDeletion);
      card.deletedOn = deletionDate.toISOString();
      card.isDeleted = true;
      card.modifiedOn = now.toISOString();

      // Save the updated card
      await db.cards.put(card);

      const updatedCards = await findAllCardsByDeckId(deckId, true);
      setCards(updatedCards);
    } catch (error) {}
  };

  const getCharLimit = () => (isSmallScreen ? 25 : 55);

  const cardItemText = (card) => {
    const charLimit = getCharLimit();
    return (
      <Typography variant="body1" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }}>
        {card.sideA.length > charLimit ? card.sideA.substring(0, charLimit) + "..." : card.sideA}
      </Typography>
    );
  };

  const cardSecondaryText = (card) => {
    const charLimit = getCharLimit();
    return (
      <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: ".8rem", sm: ".9rem" } }}>
        {card.sideB.length > charLimit ? card.sideB.substring(0, charLimit) + "..." : card.sideB}
      </Typography>
    );
  };

  const cardSecondaryAction = (card) => (
    <Fade in={isEditClicked} timeout={300} unmountOnExit>
      <Stack direction="row" spacing={0.25}>
        <IconButton edge="end" aria-label="restore" onClick={() => handleRestore(card.id)}>
          <RestoreRoundedIcon color="primary" />
        </IconButton>
        <IconButton edge="end" aria-label="delete" onClick={() => openDeleteDialog(card.id)}>
          <DeleteRoundedIcon color="error" />
        </IconButton>
      </Stack>
    </Fade>
  );

  return (
    <Stack data-testid="cards-bin-screen" spacing={1.5}>
      <Box>
        <BreadcrumbsNav />
      </Box>
      <ListDropdownMenu title="Recently Deleted">
        <MenuItem onClick={() => dispatch(navigateTo("CardsBin"))}>
          <ListItemText primary="Recently Deleted" />
        </MenuItem>
        <MenuItem onClick={() => dispatch(navigateTo("MyCards"))}>
          <ListItemText primary="My Cards" />
        </MenuItem>
      </ListDropdownMenu>
      <Stack>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" alignItems="center"></Box>
          <Box>
            <Button
              color="primary"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.1rem",
                },
                color: "text.primary",
                borderRadius: 1.5,
              }}
              onClick={toggleEdit}>
              {isEditClicked ? "Done" : "Edit"}
            </Button>
          </Box>
        </Box>
      </Stack>
      {cards.length === 0 ? (
        <PaperListPlaceholder
          icon={RecyclingRoundedIcon} // Icon component
          primaryText="All cleaned up!" // Primary text
          secondaryText="Cards you delete will appear here and be permanently deleted after 30 days." // Secondary text
        />
      ) : (
        <PaperList
          items={cards}
          onItemSelect={() => {}} // Remove as not required
          isEditClicked={isEditClicked}
          secondaryAction={cardSecondaryAction}
          itemText={cardItemText}
          itemSecondaryText={cardSecondaryText}
          showForwardIcon={false}
          itemHeight="4rem"
          dividerInsetMargin="1rem"
        />
      )}
      <DeleteCard open={deleteDialogOpen} onClose={handleCloseDeleteDialog} onDelete={handleConfirmDelete} cardName={cards.find((card) => card.id === cardToDelete)?.sideA || ""} />
    </Stack>
  );
};

export default CardsBinScreen;
