/* eslint-disable no-unused-vars */
import { useSelector, useDispatch } from "react-redux";
import { isTokenValid } from "../utilities/validateToken";
import { signOut } from "../redux/features/auth/authSlice";

export const useAuth = () => {
  const dispatch = useDispatch();
  const tokenExpiration = useSelector((state) => state.auth.tokenExpiration);
  const validToken = isTokenValid(tokenExpiration);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return { isAuthenticated };
};
