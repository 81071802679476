import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import PolicyLinks from "../../components/common/PolicyLinks";
import BrandTitle from "../../components/common/BrandTitle";
import BrandLogo from "../../components/common/BrandLogo";
import GoogleSignInButton from "../../components/auth/GoogleSignInButton";

function StartMenu() {
  return (
    <Box
      data-testid="start-menu-layout"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{
        backgroundColor: {
          xs: "#fcdbb9",
          md: "inherit",
        },

        paddingTop: "5%",
        paddingBottom: "25%",
      }}>
      <Stack spacing={25} alignItems="center" borderColor={"red"}>
        <BrandLogo />
        <Stack spacing={6} alignItems="center">
          <BrandTitle />
          <Typography variant="brand">Welcome back</Typography>
          <GoogleSignInButton>Sign In with Google</GoogleSignInButton>
          <PolicyLinks />
        </Stack>
      </Stack>
    </Box>
  );
}

export default StartMenu;
