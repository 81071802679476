import { createSlice } from "@reduxjs/toolkit";

export const deckSlice = createSlice({
  name: "deck",
  initialState: {
    currentDeckId: null,
    currentDeckDetails: null,
    decksCount: 0,
  },
  reducers: {
    setCurrentDeckId: (state, action) => {
      state.currentDeckId = action.payload;
    },
    setCurrentDeckDetails: (state, action) => {
      if (action.payload.modifiedOn instanceof Date) {
        action.payload.modifiedOn = action.payload.modifiedOn.toISOString();
      }
      state.currentDeckDetails = action.payload;
    },
    setDeckCount: (state, action) => {
      state.decksCount = action.payload;
    },
    resetDeckState: (state) => {
      state.currentDeckId = null;
      state.currentDeckDetails = null;
      state.decksCount = 0;
    },
  },
});

export const { setCurrentDeckId, setCurrentDeckDetails, setDeckCount, resetDeckState } = deckSlice.actions;
export default deckSlice.reducer;
