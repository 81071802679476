import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";

const DeleteDeck = ({ open, onClose, onDelete, deckName }) => {
  return (
    <Dialog
      data-testid="delete-deck-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="delete-deck-dialog-title"
      aria-describedby="delete-deck-dialog-description"
      PaperProps={{
        sx: {
          marginTop: { xs: -25, sm: 0 },
          display: "flex",
          flexDirection: "column",
          maxWidth: "none",
          width: { xs: "90%", sm: "70%", md: "40%" },
          height: { xs: "auto", sm: "auto", md: "auto" },
          borderRadius: 3,
        },
      }}>
      <DialogContent>
        <Typography variant="h5" id="delete-deck-dialog-description" sx={{ fontWeight: 600 }}>
          {deckName ? "Delete Deck:" : "Delete Deck"}
          {deckName && ` "${deckName}"`}
        </Typography>
        <Typography variant="body1">Are you sure you want to delete this deck? All associated cards will also be deleted. This action cannot be undone.</Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "24px 24px 10px 24px", flexShrink: 0 }}>
        <Button onClick={onDelete} variant="contained" color="error" fullWidth>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose} fullWidth>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDeck;
