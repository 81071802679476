import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";

const CreateDeck = ({ open, onClose, onSubmit, initialName = "", initialDescription = "" }) => {
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);

  useEffect(() => {
    if (open) {
      setName(initialName);
      setDescription(initialDescription);
    }
  }, [open, initialName, initialDescription]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <Dialog
      data-testid="create-deck-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          marginTop: { xs: -30, sm: 0 },
          display: "flex",
          flexDirection: "column",
          maxWidth: "none",
          width: { xs: "90%", sm: "70%", md: "40%" },
          borderRadius: 3,
        },
      }}>
      <form onSubmit={handleFormSubmit} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <DialogContent
          sx={{
            padding: "10px 24px 10px 24px",
            overflowY: "auto",
            flexGrow: 1,
          }}>
          <TextField autoFocus margin="dense" id="deckName" name="deckName" label="Deck Name" type="text" fullWidth variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
          <TextField
            margin="dense"
            id="deckDescription"
            name="deckDescription"
            label="Description"
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: "0px 24px 10px 24px",
            flexShrink: 0,
          }}>
          <Button variant="outlined" onClick={onClose} fullWidth>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateDeck;
