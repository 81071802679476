import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Fade, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import StyleRoundedIcon from "@mui/icons-material/StyleRounded";
import db from "../../database/db";
import { createCard, findAllCardsByDeckId, updateCard } from "../../services/data/cardDataService";
import { findDeckById, updateDeck } from "../../services/data/deckDataService";
import BreadcrumbsNav from "../../components/common/BreadcrumbsNav";
import CardsScreenMenu from "../../components/menus/CardsScreenMenu";
import CreateCard from "../../components/dialogs/CreateCard";
import PaperList from "../../components/list/PaperList";
import PaperListPlaceholder from "../../components/list/PaperListPlaceholder";
import PaperListLoading from "../../components/list/PaperListLoading";
import ReviewCards from "../../components/dialogs/ReviewCards";
import StudyCards from "../../components/dialogs/StudyCards";
import UpdateCard from "../../components/dialogs/UpdateCard";
import UpdateDeck from "../../components/dialogs/UpdateDeck";
import { navigateTo } from "../../redux/features/screen/screenSlice";

const MyCardsScreen = () => {
  const dispatch = useDispatch();
  const deckId = useSelector((state) => state.deck.currentDeckId);
  const userId = useSelector((state) => state.auth.userId);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const daysBeforeDeletion = useSelector((state) => state.syncing.daysBeforeDeletion);
  const lastSynced = useSelector((state) => state.syncing.lastSynced);
  const [deck, setDeck] = useState(null);
  const [cards, setCards] = useState([]);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [openCreateCardDialog, setOpenCreateCardDialog] = useState(false);
  const [openUpdateDeckDialog, setOpenUpdateDeckDialog] = useState(false);
  const [openUpdateCardDialog, setOpenUpdateCardDialog] = useState(false);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [openStudyDialog, setOpenStudyDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [cardCount, setCardCount] = useState(null);
  const [card, setCard] = useState(null);
  const isSyncing = useSelector((state) => state.syncing.isSyncing);

  const globalDeckDetails = useSelector((state) => state.deck.currentDeckDetails);
  useEffect(() => {
    if (globalDeckDetails) {
      setDeck(globalDeckDetails);
    }
  }, [globalDeckDetails]);

  const toggleReviewDialog = () => {
    setOpenReviewDialog(!openReviewDialog);
  };

  const toggleStudyDialog = () => {
    setOpenStudyDialog(!openStudyDialog);
  };

  const handleUpdateDeck = async (updatedFields) => {
    if (!deckId) {
      return;
    }

    try {
      const updatedDeck = await updateDeck(deckId, updatedFields);
      if (updatedDeck) {
        setDeck(updatedDeck);
      } else {
      }
    } catch (error) {
    } finally {
      setOpenUpdateDeckDialog(false);
    }
  };

  const handleCreateCard = () => {
    setCard(null);
    setOpenCreateCardDialog(true);
  };

  const handleCloseCreateCardDialog = () => {
    setOpenCreateCardDialog(false);
  };

  const handleOpenUpdateDeckDialog = () => {
    setOpenUpdateDeckDialog(true);
  };

  const handleCloseUpdateDeckDialog = () => {
    setOpenUpdateDeckDialog(false);
  };

  const toggleEdit = () => {
    //TODO: Reset toggle on click away
    setIsEditClicked((prev) => !prev);
  };

  const handleCardSelect = (cardId) => {
    const selected = cards.find((card) => card.id === cardId);
    if (selected) {
      setCard(selected);
      setOpenUpdateCardDialog(true);
    }
  };
  const handleUpdateCard = async (sideA, sideB) => {
    if (!card?.id) {
      return;
    }

    try {
      const updatedCardData = {
        ...card,
        sideA,
        sideB,
        modifiedOn: new Date().toISOString(),
      };

      await updateCard(card.id, updatedCardData);

      // Update the deck's modifiedOn date after updating the card
      const deckUpdatePayload = {
        modifiedOn: new Date().toISOString(),
      };
      await updateDeck(deckId, deckUpdatePayload);

      // Fetch and update the state of cards and deck
      const updatedCards = await findAllCardsByDeckId(deckId);
      setCards(updatedCards);

      const updatedDeck = await findDeckById(deckId);
      if (updatedDeck) {
        setDeck(updatedDeck);
      }
    } catch (error) {
    } finally {
      setOpenUpdateCardDialog(false);
    }
  };

  const handleCloseUpdateCardDialog = () => {
    setOpenUpdateCardDialog(false);
  };

  // Fetch Deck
  useEffect(() => {
    const fetchDeck = async () => {
      if (!deckId) {
        return;
      }

      try {
        const fetchedDeck = await findDeckById(deckId);
        if (fetchedDeck) {
          setDeck(fetchedDeck);
        } else {
        }
      } catch (error) {}
    };
    fetchDeck();
  }, [deckId]);

  // Fetch Cards
  useEffect(() => {
    const getCards = async () => {
      if (!deckId) {
        return;
      }

      // let timeoutId = setTimeout(() => setIsLoading(true), 500);

      try {
        const loadedCards = await findAllCardsByDeckId(deckId);
        // clearTimeout(timeoutId);
        setCards(loadedCards);
        setCardCount(loadedCards.length);
      } catch (error) {
        // Handle error here if needed
      } finally {
        setIsLoading(false);
        setInitialLoad(false);
      }
    };
    getCards();
  }, [deckId, lastSynced]);

  // CREATE Card
  const handleSubmitCard = async (sideA, sideB) => {
    if (!deckId) {
      return;
    }
    // Check if both sideA and sideB are empty
    if (!sideA.trim() && !sideB.trim()) {
      return;
    }
    if (!userId) {
      return;
    }
    try {
      const cardData = {
        sideA,
        sideB,
        deckId,
        modifiedOn: new Date().toISOString(),
        createdBy: userId,
      };

      // Create a new card
      const cardId = await createCard(cardData, deckId, userId);

      // Prepare update payload for the deck
      const updatePayload = {
        cards: deck?.cards ? [...deck.cards, cardId] : [cardId],
        modifiedOn: new Date().toISOString(), // This line is optional as updateDeck will set it
      };

      // Update the deck
      const updatedDeck = await updateDeck(deckId, updatePayload);

      // Update local state for deck
      setDeck(updatedDeck);
      // Update local state for cards (add the full card object)
      const updatedCards = await findAllCardsByDeckId(deckId);
      setCards(updatedCards);
      setCardCount(updatedCards.length);
    } catch (error) {
    } finally {
      handleCloseCreateCardDialog();
    }
  };

  // SOFT DELETE Cards
  const handleSoftDelete = async (event, cardId) => {
    event.stopPropagation(); // Prevent event bubbling

    try {
      const card = await db.cards.get(cardId);

      if (!card) {
        return;
      }

      const now = new Date();
      const deletionDate = new Date(now);
      deletionDate.setDate(deletionDate.getDate() + daysBeforeDeletion);
      card.deletedOn = deletionDate.toISOString();
      card.modifiedOn = now.toISOString();

      await db.cards.put(card);

      const updatedCards = await findAllCardsByDeckId(deckId);
      setCards(updatedCards);
      setCardCount(updatedCards.length);
    } catch (error) {}
  };

  const getCharLimit = () => (isSmallScreen ? 25 : 55);

  const cardItemText = (card) => {
    const charLimit = getCharLimit();
    return (
      <Typography variant="body1" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }}>
        {card.sideA.length > charLimit ? card.sideA.substring(0, charLimit) + "..." : card.sideA}
      </Typography>
    );
  };

  const cardSecondaryText = (card) => {
    const charLimit = getCharLimit();
    return (
      <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: ".8rem", sm: ".9rem" } }}>
        {card.sideB.length > charLimit ? card.sideB.substring(0, charLimit) + "..." : card.sideB}
      </Typography>
    );
  };
  const cardSecondaryAction = (card) => (
    <Fade in={isEditClicked} timeout={300} unmountOnExit>
      <Stack direction="row" spacing={0.25}>
        <IconButton edge="end" aria-label="more" onClick={(e) => handleSoftDelete(e, card.id)}>
          <DeleteOutlineRoundedIcon color="primary" />
        </IconButton>
      </Stack>
    </Fade>
  );

  return (
    <Stack data-testid="mycards-screen" spacing={1.5}>
      <Box>
        <BreadcrumbsNav />
      </Box>
      <CardsScreenMenu
        isEditClicked={isEditClicked}
        deck={deck}
        handleUpdateDeck={handleOpenUpdateDeckDialog}
        navigateTo={navigateTo}
        dispatch={dispatch}
        onReviewClick={toggleReviewDialog}
        onStudyClick={toggleStudyDialog}
      />
      <Stack>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" alignItems="center">
            <Button
              startIcon={<AddRoundedIcon />}
              color="primary"
              variant="text"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.1rem",
                },
                color: "text.primary",
                borderRadius: 1.5,
              }}
              onClick={handleCreateCard}>
              Card
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="text"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.1rem",
                },
                color: "text.primary",
                borderRadius: 1.5,
              }}
              onClick={toggleEdit}>
              {isEditClicked ? "Done" : "Edit"}
            </Button>
          </Box>
        </Box>
      </Stack>

      {isLoading || (isSyncing && cardCount === 0) ? (
        <PaperListLoading count={3} itemHeight="4rem" dividerInsetMargin="3rem" showPrimaryAction={true} showSecondaryText={true} />
      ) : !initialLoad && cardCount === 0 ? (
        <PaperListPlaceholder icon={StyleRoundedIcon} primaryText="No Cards found" secondaryText="Create a new card to get started." />
      ) : (
        <PaperList
          items={cards}
          onItemSelect={handleCardSelect}
          isEditClicked={isEditClicked}
          secondaryAction={cardSecondaryAction}
          itemText={cardItemText}
          itemSecondaryText={cardSecondaryText}
          showForwardIcon={false}
          itemHeight="4rem"
          dividerInsetMargin="1rem"
        />
      )}
      <CreateCard open={openCreateCardDialog} onClose={handleCloseCreateCardDialog} onSubmit={handleSubmitCard} card={card} />
      <UpdateDeck open={openUpdateDeckDialog} onClose={handleCloseUpdateDeckDialog} onSubmit={handleUpdateDeck} deckId={deckId} />
      <UpdateCard open={openUpdateCardDialog} onClose={handleCloseUpdateCardDialog} onSubmit={handleUpdateCard} card={card} />
      <ReviewCards open={openReviewDialog} onClose={() => setOpenReviewDialog(false)} deckId={deckId} />
      <StudyCards open={openStudyDialog} onClose={() => setOpenStudyDialog(false)} deckId={deckId} />
    </Stack>
  );
};

export default MyCardsScreen;
