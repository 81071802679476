import React from "react";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { findUserByAccessToken } from "../../services/drive/googleDriveService";
import { signIn, updateAccessToken } from "../../redux/features/auth/authSlice";

function GoogleSignInButton({ children }) {
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      dispatch(updateAccessToken({ token: tokenResponse.access_token, expiresIn: tokenResponse.expires_in }));
      const userInfo = await findUserByAccessToken(tokenResponse.access_token);

      // Send User ID to Google Analytics
      if (window.gtag) {
        window.gtag("config", "G-526BHSQX91", { user_id: userInfo.id });
      }

      // Sign in after importing data

      dispatch(signIn({ isAuthenticated: true, user: userInfo, userId: userInfo.id }));
    },
    onError: () => console.log("Login Failed"),
    scope: "openid email profile https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata",
    flow: "implicit",
    // ux_mode and redirect_uri setup if needed
  });

  return (
    <Button data-testid="google-signin-button" startIcon={<GoogleIcon />} variant="contained" color="secondary" onClick={() => login()}>
      {children}
    </Button>
  );
}

export default GoogleSignInButton;
