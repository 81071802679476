import React from "react";
import { Box, Paper, Typography } from "@mui/material";

const PaperListPlaceholder = ({ icon: Icon, primaryText, secondaryText }) => {
  return (
    <Paper
      data-testid="paper-list-placeholder"
      elevation={0}
      sx={{
        borderRadius: 3,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 300,
      }}>
      <Box sx={{ textAlign: "center", ml: { md: 20 } }}>
        {Icon && <Icon color="secondary" sx={{ fontSize: 40 }} />}
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">{primaryText}</Typography>
          {secondaryText && (
            <Typography p={2} variant="body2" color="text.secondary">
              {secondaryText}
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default PaperListPlaceholder;
