import React from "react";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const PaperList = ({ items, onItemSelect, isEditClicked, ItemIcon, secondaryAction, itemText, itemSecondaryText, showForwardIcon, itemHeight, dividerInsetMargin }) => {
  return (
    <Paper data-testid="paper-list" elevation={0} sx={{ borderRadius: 3, overflow: "hidden" }}>
      <List disablePadding>
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            <ListItem
              onClick={() => onItemSelect(item.id)}
              disablePadding
              secondaryAction={isEditClicked && secondaryAction(item)}
              sx={{
                "&:first-of-type .MuiListItemButton-root": { borderTopLeftRadius: 3, borderTopRightRadius: 3 },
                "&:last-of-type .MuiListItemButton-root": { borderBottomLeftRadius: 3, borderBottomRightRadius: 3 },
              }}>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: itemHeight,
                  "&:hover": {
                    backgroundColor: "secondary.light",
                    "& .MuiListItemIcon-root > *": {
                      color: "secondary.dark",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "secondary.dark",
                    },
                  },
                }}>
                {ItemIcon && (
                  <ListItemIcon sx={{ minWidth: "auto", marginRight: "0.5rem", fontSize: { xs: "1.5rem", sm: "1.7rem" } }}>
                    <ItemIcon color="secondary" sx={{ fontSize: "inherit" }} />
                  </ListItemIcon>
                )}
                <ListItemText primary={itemText(item)} secondary={itemSecondaryText ? itemSecondaryText(item) : null} />
                {!isEditClicked && showForwardIcon && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2" color="text.secondary" sx={{ marginRight: 1, fontSize: { xs: ".8rem", sm: ".9rem" } }}>
                      {item.cardCount}
                    </Typography>
                    <ArrowForwardIosRoundedIcon data-testid="arrow-icon" color="secondary" sx={{ fontSize: { xs: ".8rem", sm: "1.2rem" } }} />
                  </div>
                )}
              </ListItemButton>
            </ListItem>
            {index !== items.length - 1 && <Divider variant="inset" sx={{ marginLeft: dividerInsetMargin }} />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default PaperList;
