import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs, Typography } from "@mui/material";
import { navigateTo, selectCurrentScreen } from "../../redux/features/screen/screenSlice";

const BreadcrumbsNav = () => {
  const dispatch = useDispatch();
  const currentScreen = useSelector(selectCurrentScreen);
  const deckDetails = useSelector((state) => state.deck.currentDeckDetails);

  const screenToBreadcrumbName = {
    Home: "Home",
    MyDecks: "My Decks",
    DecksBin: "Bin",
    MyCards: "My Cards",
    CardsBin: "Bin",
  };

  const charLimit = 30;

  const handleNavigate = (screen) => {
    if (screen === "Home") {
      window.location.href = "https://basicflashcards.com/";
    } else {
      dispatch(navigateTo(screen));
    }
  };

  const breadcrumbStyle = {
    fontSize: { lg: "1rem", md: "1rem", sm: "1rem", xs: "0.8rem" },
    cursor: "pointer",
    color: "text.secondary",
  };

  const lastBreadcrumbStyle = {
    ...breadcrumbStyle,
    fontWeight: "600",
    fontSize: { lg: "1rem", md: "1rem", sm: "1rem", xs: "0.8rem" },
    color: "text.primary",
  };

  const generateBreadcrumbTrail = () => {
    let trail = ["Home"];
    switch (currentScreen) {
      case "MyDecks":
        trail.push("MyDecks");
        break;
      case "DecksBin":
        trail.push("MyDecks", "DecksBin");
        break;
      case "MyCards":
        const deckName = deckDetails && deckDetails.name.length > charLimit ? "..." : deckDetails.name;
        trail.push("MyDecks", deckName);
        break;
      case "CardsBin":
        const deckNameForBin = deckDetails && deckDetails.name.length > charLimit ? "..." : deckDetails.name;
        trail.push("MyDecks", deckNameForBin, "CardsBin");
        break;
      default:
        break;
    }
    return trail;
  };

  const breadcrumbTrail = generateBreadcrumbTrail();

  return (
    <Breadcrumbs data-testid="breadcrumbs-nav" aria-label="breadcrumb" sx={{ ml: 1 }}>
      {breadcrumbTrail.map((screen, index) => {
        const isLastItem = index === breadcrumbTrail.length - 1;
        const breadcrumbName = screenToBreadcrumbName[screen] || screen;
        return (
          <Typography sx={isLastItem ? lastBreadcrumbStyle : breadcrumbStyle} onClick={() => handleNavigate(screen)} key={`breadcrumb-${index}`}>
            {breadcrumbName}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;
