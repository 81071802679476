import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Fade, IconButton, ListItemText, MenuItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import RecyclingRoundedIcon from "@mui/icons-material/RecyclingRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { findAllDecksByUserId } from "../../services/data/deckDataService";
import db from "../../database/db";
import BreadcrumbsNav from "../../components/common/BreadcrumbsNav";
import ListDropdownMenu from "../../components/menus/ListDropdownMenu";
import { navigateTo } from "../../redux/features/screen/screenSlice";
import PaperList from "../../components/list/PaperList";
import PaperListPlaceholder from "../../components/list/PaperListPlaceholder";
import DeleteDeck from "../../components/dialogs/DeleteDeck";

const DecksBinScreen = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [decks, setDecks] = useState([]);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const lastSynced = useSelector((state) => state.syncing.lastSynced);
  const daysBeforeDeletion = useSelector((state) => state.syncing.daysBeforeDeletion);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deckToDelete, setDeckToDelete] = useState(null);

  const openDeleteDialog = (deckId) => {
    setDeckToDelete(deckId);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (deckToDelete) {
      await handleHardDelete(deckToDelete);
    }
    setDeleteDialogOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // Toggle function for the Edit/Done button
  const toggleEdit = () => {
    setIsEditClicked((prev) => !prev);
  };

  // GET Decks from IndexedDB
  useEffect(() => {
    const getDecks = async () => {
      if (!userId) return;

      const loadedDecks = await findAllDecksByUserId(userId, true);

      setDecks(loadedDecks);
    };
    getDecks();
  }, [userId, lastSynced]);

  const handleRestore = async (deckId) => {
    try {
      const deck = await db.decks.get(deckId);

      if (!deck) {
        return;
      }

      deck.deletedOn = null;
      deck.modifiedOn = new Date().toISOString(); // Update the modifiedOn field to now

      await db.decks.put(deck);

      const updatedDecks = await findAllDecksByUserId(userId, true);
      setDecks(updatedDecks);
    } catch (error) {
      <Typography variant="body1">Umm...couldn't load decks 😶</Typography>;
    }
  };

  const handleHardDelete = async (deckId) => {
    try {
      const deck = await db.decks.get(deckId);

      if (!deck) {
        return;
      }

      // Mark all cards in the deck as deleted
      const now = new Date();
      const deletionDate = new Date(now);
      deletionDate.setDate(deletionDate.getDate() + daysBeforeDeletion);

      const cards = await db.cards.where({ deckId }).toArray();
      const updatePromises = cards.map((card) => {
        return db.cards.update(card.id, {
          isDeleted: true,
          deletedOn: deletionDate.toISOString(),
          modifiedOn: now.toISOString(),
        });
      });
      await Promise.all(updatePromises);

      // Proceed with marking the deck as deleted
      deck.deletedOn = deletionDate.toISOString();
      deck.isDeleted = true;
      deck.modifiedOn = now.toISOString();

      await db.decks.put(deck);

      const updatedDecks = await findAllDecksByUserId(userId, true);
      setDecks(updatedDecks);
    } catch (error) {}
  };

  const getCharLimit = () => (isSmallScreen ? 25 : 55);

  const deckItemText = (deck) => {
    const charLimit = getCharLimit();
    return (
      <Typography variant="body1" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }}>
        {deck.name.length > charLimit ? deck.name.substring(0, charLimit) + "..." : deck.name}
      </Typography>
    );
  };

  //TODO: Add get cardCount and add at end of record
  const deckSecondaryText = (deck) => {
    // const cardCountStr = deck.cardCount !== undefined ? deck.cardCount.toString() : "0";

    return (
      <Typography variant="body2" color="text.secondary" sx={{ marginRight: 1, fontSize: { xs: ".8rem", sm: ".9rem" } }}>
        {/* {cardCountStr} */}
      </Typography>
    );
  };

  const deckSecondaryAction = (deck) => (
    <Fade in={isEditClicked} timeout={300} unmountOnExit>
      <Stack direction="row" spacing={0.25}>
        <IconButton edge="end" aria-label="restore" onClick={() => handleRestore(deck.id)}>
          <RestoreRoundedIcon color="primary" />
        </IconButton>
        <IconButton edge="end" aria-label="delete" onClick={() => openDeleteDialog(deck.id)}>
          <DeleteRoundedIcon color="error" />
        </IconButton>
      </Stack>
    </Fade>
  );

  return (
    <Stack data-testid="decks-bin-screen" spacing={1.5}>
      <Box>
        <BreadcrumbsNav />
      </Box>
      <ListDropdownMenu title="Recently Deleted">
        <MenuItem onClick={() => dispatch(navigateTo("DecksBin"))}>
          <ListItemText primary="Recently Deleted" />
        </MenuItem>
        <MenuItem onClick={() => dispatch(navigateTo("MyDecks"))}>
          <ListItemText primary="My Decks" />
        </MenuItem>
      </ListDropdownMenu>
      <Stack>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" alignItems="center"></Box>
          <Box>
            <Button
              color="primary"
              sx={{
                fontSize: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1.1rem",
                },
                color: "text.primary",
                borderRadius: 1.5,
              }}
              onClick={toggleEdit}>
              {isEditClicked ? "Done" : "Edit"}
            </Button>
          </Box>
        </Box>
      </Stack>
      {decks.length === 0 ? (
        <PaperListPlaceholder icon={RecyclingRoundedIcon} primaryText="All cleaned up!" secondaryText="Decks you delete will appear here and be permanently deleted after 30 days." />
      ) : (
        <PaperList
          items={decks}
          onItemSelect={() => {}}
          isEditClicked={isEditClicked}
          ItemIcon={LayersRoundedIcon}
          secondaryAction={deckSecondaryAction}
          itemText={deckItemText}
          itemSecondaryText={deckSecondaryText}
          itemHeight="3rem"
          dividerInsetMargin="3rem"
        />
      )}
      <DeleteDeck open={deleteDialogOpen} onClose={handleCloseDeleteDialog} onDelete={handleConfirmDelete} deckName={decks.find((deck) => deck.id === deckToDelete)?.name || ""} />
    </Stack>
  );
};

export default DecksBinScreen;
