import React from "react";
import { Box, Dialog, IconButton, Paper, Typography } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import { useCardData, useCardNavigation } from "../../hooks/card";

const ReviewCards = ({ open, onClose, deckId }) => {
  const cards = useCardData(deckId, open);
  const { currentIndex, handleNext, handlePrev, handleFlip, showBack, isTransitioning, setCurrentIndex, setShowBack } = useCardNavigation(cards.length);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setShowBack(false);
      setCurrentIndex(0);
    }, 150);
  };

  const currentCard = cards[currentIndex] || { sideA: "", sideB: "" };

  return (
    <Dialog
      data-testid="review-card-dialog"
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          display: "grid",
          gridTemplateAreas: {
            xs: `"content" "buttons"`,
            sm: `"buttons content"`,
          },
          gridTemplateColumns: { sm: "auto 1fr" },
          gridTemplateRows: { xs: "1fr auto" },
          borderRadius: 0,
          boxShadow: "none",
          backgroundColor: "transparent",
          height: { xs: "70%", sm: "90%", md: "50%" },
          width: { xs: "80%", sm: "90%", md: "60%" },
          perspective: "1000px",
        },
      }}>
      <Box
        sx={{
          gridArea: "buttons",
          display: "flex",
          flexDirection: { xs: "row", sm: "column" },
          justifyContent: "space-around",
          alignItems: "center",
          p: 1,
          height: "100%",
          width: "100%",
        }}>
        <IconButton
          data-testid="prev-button"
          onClick={handlePrev}
          disabled={currentIndex === 0}
          sx={{
            backgroundColor: "button.easy",
            color: "text.dark",
            "&:hover": {
              backgroundColor: "paper.dark",
            },
          }}>
          <ArrowBackIosNewRoundedIcon fontSize="large" />
        </IconButton>
        <IconButton
          data-testid="flip-button"
          onClick={handleFlip}
          sx={{
            backgroundColor: "secondary.light",
            color: "text.dark",
            "&:hover": {
              backgroundColor: "secondary.main",
            },
          }}>
          <CachedRoundedIcon fontSize="large" />
        </IconButton>

        <IconButton
          data-testid="next-button"
          onClick={handleNext}
          disabled={currentIndex === cards.length - 1}
          sx={{
            backgroundColor: "button.easy",
            color: "text.dark",
            "&:hover": {
              backgroundColor: "paper.dark",
            },
          }}>
          <ArrowForwardIosRoundedIcon fontSize="large" />
        </IconButton>
      </Box>

      <Box
        onClick={handleFlip}
        sx={{
          gridArea: "content",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          // border: "2px solid purple",
        }}>
        <Paper
          elevation={3}
          sx={{
            flexGrow: 1,
            borderRadius: 3,
            transition: isTransitioning ? "none" : "transform 0.4s",
            transformStyle: "preserve-3d",
            transform: showBack ? "rotateY(180deg)" : "rotateY(0deg)",
            position: "relative",
            height: "100%",
            m: 1,
          }}>
          {/* Front side of the card */}
          <Box
            sx={{
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}>
            <div style={{ padding: "10px" }}>
              <Typography variant="h5" align="left" color="text.primary" sx={{ m: 4 }}>
                {currentCard.sideA}
              </Typography>
            </div>
          </Box>
          {/* Back side of the card */}
          <Box
            sx={{
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
              position: "absolute",
              width: "100%",
              height: "100%",
              overflow: "auto",
              transform: "rotateY(180deg)",
            }}>
            <div style={{ padding: "10px" }}>
              <Typography variant="h5" align="left" color="text.primary" sx={{ m: 4 }}>
                {currentCard.sideB}
              </Typography>
            </div>
          </Box>
        </Paper>
      </Box>
    </Dialog>
  );
};

export default ReviewCards;
