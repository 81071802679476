import React from "react";
import { Box, Divider, Stack, ListItemText, MenuItem } from "@mui/material";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import ListDropdownMenu from "../menus/ListDropdownMenu";
import NoteRoundedIcon from "@mui/icons-material/NoteRounded";

const CardsScreenMenu = ({ deck, handleUpdateDeck, navigateTo, dispatch, onReviewClick, onStudyClick }) => {
  return (
    <Stack data-testid="cards-screen-menu" direction="row" justifyContent="space-between" width="100%">
      <ListDropdownMenu title={deck ? deck.name : "..."}>
        <MenuItem onClick={() => dispatch(navigateTo("MyCards"))}>
          <ListItemText primary="My Cards" />
        </MenuItem>
        <MenuItem onClick={() => dispatch(navigateTo("CardsBin"))}>
          <ListItemText primary="Recently Deleted" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={onReviewClick}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <ListItemText primary="Review" />
            <NoteRoundedIcon />
          </Box>
        </MenuItem>
        {/* <MenuItem onClick={onStudyClick}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <ListItemText primary="Study" />
            <ImportContactsRoundedIcon />
          </Box>
        </MenuItem> */}
        <Divider />
        <MenuItem onClick={handleUpdateDeck}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <ListItemText primary="Update" />
            <EditNoteRoundedIcon />
          </Box>
        </MenuItem>
      </ListDropdownMenu>
    </Stack>
  );
};

export default CardsScreenMenu;
